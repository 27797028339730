export default {
  init() {
    // JavaScript to be fired on all pages
    slideoutSearch()

    //if mobile
    mobileMenuArrows()
    mobileSubmenuToggle()

    //fire header transparent - function only on home
    if (document.body.classList.contains('home')) {
      headerTransparentSwitch()
    }

    //if mobile
    mobileMenuIcon()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

//JQ - Version
function slideoutSearch() {
  $('.formbtn').on('click', function () {
    $('.input').toggleClass('inclicked')
    $('.formbtn').toggleClass('close')
  })
}
//JS - Version
// function slideoutSearch() {
//   document.querySelector('.formbtn').addEventListener('click', function () {
//     document.querySelector('.input').classList.toggle('inclicked');
//     document.querySelector('.formbtn').classList.toggle('close');
//   });
// }

function headerTransparentSwitch() {
  var myNav = document.getElementById('header')

  window.onscroll = function () {
    'use strict'
    if (
      document.body.scrollTop >= 280 ||
      document.documentElement.scrollTop >= 280
    ) {
      myNav.classList.add('scrolled')
    } else {
      myNav.classList.remove('scrolled')
    }
  }
}

function mobileMenuIcon() {
  document
    .querySelector('.mobile-nav-button')
    .addEventListener('click', function () {
      document.querySelector('.animated-icon').classList.toggle('open')
    })
}

//add arrows to parent menu items (li)
function mobileMenuArrows() {
  $('li.menu-item-has-children').append($('<span></span>'))
}

function mobileSubmenuToggle() {
  $('li.menu-item-has-children span').click(function (e) {
    e.preventDefault()
    $(this).toggleClass('rotate')
    $(this).parent().find('.sub-menu').toggle()
  })
}
//JS -Version
// function mobileSubmenuToggle() {
//   var btnContainer = document.getElementById('navbarMobileMenuWrap');
//   var btns = btnContainer.querySelectorAll('li.menu-item-has-children');

//   for (var i = 0; i < btns.length; i++) {
//     btns[i].addEventListener('click', function () {
//       document.querySelector('.menuActive')
//         ? document.querySelector('.menuActive').classList.remove('menuActive')
//         : '';
//       //this.classList.add('menuActive');

//       this.classList.querySelector('ul.sub-menu').classList.add('menuActive');
//     });
//   }
// }
